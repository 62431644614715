<template>
  <div>
    <Header2022 :navbg='true'></Header2022>
    <div class="content">
      <el-upload class="avatar-uploader" :action="avatarUpDate" name="avatar" :headers="headers" :show-file-list="false" :on-success="handleAvatarSuccess" :before-upload="beforeAvatarUpload">
        <img v-if="imageUrl" :src="imageUrl" class="avatar">
        <i v-else class="el-icon-plus avatar-uploader-icon"></i>
      </el-upload>
      <div class="info-list">
        <div class="info-item">
          <div class="left">手机号</div>
          <div class="right">
            <el-input v-model="mobile" placeholder="请输入手机号" disabled></el-input>
          </div>
        </div>
        <div class="info-item">
          <div class="left">真实姓名</div>
          <div class="right">
            <el-input v-model="name" placeholder="请输入真实姓名"></el-input>
          </div>
        </div>
        <div class="info-item">
          <div class="left">昵称</div>
          <div class="right">
            <el-input v-model="nickName" placeholder="请输入昵称"></el-input>
          </div>
        </div>
        <div class="info-item">
          <div class="left">性别</div>
          <div class="right">
            <el-radio v-model="gender" label="1">男</el-radio>
            <el-radio v-model="gender" label="2">女</el-radio>
            <el-radio v-model="gender" label="3">保密</el-radio>
          </div>
        </div>
        <div class="info-item">
          <div class="left">生日信息</div>
          <div class="right">
            <el-date-picker v-model="birthday" type="date" value-format="yyyy-MM-dd" placeholder="选择生日日期">
            </el-date-picker>
          </div>
        </div>
        <div class="info-item">
          <div class="left">身份证</div>
          <div class="right">
            <el-input v-model="idCard" placeholder="请输入身份证号码"></el-input>
          </div>
        </div>

      </div>

      <div class="btn">
        <el-button type="text" @click="submit">保存修改</el-button>
      </div>

    </div>
   <Footer2022></Footer2022>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import Header2022 from '@/components/header2022.vue'
import Footer2022 from '@/components/footer2022.vue'
import utils from '@/common/utils'

export default {
  data () {
    return {
      imageUrl:"",
      name: "",
      mobile: "",
      gender: "",
      nickName: "",
      birthday: "",
      idCard: "",
    }
  },
  components: {
    Header2022,
    Footer2022,
  },
  computed: {
    ...mapState(['user_info', 'ocApi', 'access_token']),
    avatarUpDate: function () {
      return this.ocApi + "account/me/avatar"
    },
    headers: function () {
      return {
        'platform': "GW",
        'access-token': this.access_token
      }
    }
  },
  created () {
    this.getUserInfo();
  },
  methods: {
    getUserInfo () {
      let that = this;

      that.$axios.get(that.ocApi + 'user/select_info', {
        params: {
          'telephone': that.user_info.telephone,
        }
      })
        .then(res => {
          that.imageUrl = res.data.data.avatar
          that.name = res.data.data.username
          that.mobile = res.data.data.mobile
          that.gender = res.data.data.gender + ''
          that.nickName = res.data.data.nickname
          that.birthday = res.data.data.birthday
          that.idCard = res.data.data.id_card
        })
        .catch(error => {
          that.$message.error(error.response.data.message);
        });

    },
    handleAvatarSuccess (res, file) {
      this.$message({
        message: '头像修改成功',
        type: 'success',
      })
      this.imageUrl = URL.createObjectURL(file.raw);
    },
    beforeAvatarUpload (file) {
      const isLt2M = file.size / 1024 / 1024 < 2;
      if (!isLt2M) {
        this.$message.error('上传头像图片大小不能超过 2MB!');
      }
      return isLt2M;
    },
    submit: utils.debounce(function () {
      let that = this;
      that.$axios.post(that.ocApi + 'user/update_info', {
        mobile: that.user_info.telephone,
        username: that.name,
        nickname: that.nickName,
        id_card: that.idCard,
        gender: that.gender,
        birthday: that.birthday
      })
        .then(res => {
          if (res.data.code == 1) {
            that.$message({
              message: '修改成功',
              type: 'success',
            })
            that.$store.dispatch('updateMember')
            setTimeout(() => {
              that.$router.back()
            }, 1000)
          } else {
            that.$message.error(res.data.message);
          }
        })
        .catch(error => {
          that.$message.error(error.response.data.message);
        });

    }, 500, true),
  },
}
</script>

<style lang="scss" scoped>
.content {
  padding: 110px 0 60px;
  width: 500px;
  margin: 0 auto;
  .avatar-uploader {
    text-align: center;
    :deep(.el-upload) {
      border-radius: 100%;
      cursor: pointer;
      overflow: hidden;
      width: 120px;
      height: 120px;
      border: 1px dashed #d9d9d9;
    }
    .avatar-uploader-icon {
      font-size: 28px;
      color: #8c939d;
      width: 120px;
      height: 120px;
      line-height: 120px;
      text-align: center;
    }
    .avatar {
      width: 120px;
      height: 120px;
      display: block;
    }
  }
  .info-list {
    margin-top: 60px;
    .info-item {
      display: flex;
      margin-top: 24px;
      font-size: 16px;
      align-items: center;
      color: #434343;
      .left {
        width: 70px;
        margin-right: 20px;
      }
      .right {
        flex: 1;
      }
    }
  }
  .btn {
    margin-top: 60px;
    text-align: center;
  }
}
</style>